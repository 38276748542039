<template>
  <div>
    <div class="uk-flex uk-flex-between">
      <div>
        <h1 class="uk-text-left uk-margin-remove-bottom">{{ $t('Sidebar.PushNotifications') }}</h1>
      </div>
      <div class="uk-flex">
        <div class="uk-margin-small-right">
          <form class="uk-search uk-width-auto@m uk-search-default" @submit.prevent="">
              <span class="uk-search-icon" uk-search-icon></span>
              <a v-if="searchKey" class="uk-form-icon uk-form-icon-flip" uk-icon="icon: close" @click.prevent="clearSearch()"></a>
              <input @blur="updateSearchKeyInURL()" class="uk-search-input uk-width-medium" type="search" v-model.trim="searchKey" :placeholder="$t('Generic.Labels.Search')">
          </form>
        </div>

        <div>
          <button :uk-tooltip="$t('Generic.Labels.Filter')" :aria-label="$t('Generic.Labels.Filter')" class="uk-button uk-button-primary uk-align-center uk-float-right uk-margin-small-right" style="padding-left: 10px; padding-right: 10px;">
            <img src="@/assets/filter-icon.svg" :alt="$t('Generic.Labels.Filter')" uk-svg />
          </button>
          <div style="min-width: 150px;" uk-dropdown="mode: hover; pos: bottom-left; animation: uk-animation-slide-top-small; duration: 500; delay-hide: 200">
            <ul class="uk-nav uk-dropdown-nav uk-text-left">
              <li class="uk-text-default" :class="filterClass.all" @click="changeFilter('all')"><a href="#">{{ $t('Generic.Labels.All') }}</a></li>
              <li class="uk-text-default" :class="filterClass.scheduled"><a @click="changeFilter('scheduled')">{{ $t('Pages.PushNotifications.Scheduled') }}</a></li>
              <li class="uk-text-default" :class="filterClass.sent"><a @click="changeFilter('sent')">{{ $t('Pages.PushNotifications.Sent') }}</a></li>
            </ul>
          </div>

        </div>

        <div>
          <button class="uk-button uk-button-primary new-btn" uk-toggle="target: #push-notification-modal-multi-app" type="button">
            <span class="uk-margin-small-right uk-padding-remove" uk-icon="commenting"></span> {{ $t('Pages.PushNotifications.SendMessage') }}
          </button>

        </div>

      </div>
    </div>
    <hr />
    <PushNotificationModal :appSelection="'enabled'" />

    <vue-good-table
      id="tbl-scheduled-notifications"
      v-if="allPushNotifications && allPushNotifications.length"
      styleClass="vgt-table uk-table uk-table-small uk-table-striped uk-table-hover uk-table-responsive uk-margin-remove-bottom"
      :columns="tableHeaders"
      :sort-options="{
      enabled: true,
      initialSortBy: [{field: 'title', type: 'asc'}]
                      
      }"
      :rows="filteredNotifications"
      :line-numbers="false"
      
      :pagination-options="{
        enabled: true,
        mode: 'pages',
        perPage: 10,
        position: 'bottom',
        perPageDropdown: [10, 25, 50, 100],
        dropdownAllowAll: true,
        setCurrentPage: 1,
        nextLabel: this.$t('Pages.PushNotifications.Next'),
        prevLabel: this.$t('Pages.PushNotifications.Prev'),
        rowsPerPageLabel: this.$t('Pages.PushNotifications.RowsPerPage'),
        ofLabel: 'of',
        pageLabel: this.$t('Pages.PushNotifications.Page'), // for 'pages' mode
        allLabel: this.$t('Pages.PushNotifications.All'),
      }"
    >
      <div slot="emptystate" class="vgt-center-align vgt-text-disabled">
        {{ $t( 'Pages.PushNotifications.NoRecordsFound' ) }}
      </div>
      <template slot="table-row" slot-scope="row">
        <span class="text-center" v-if="row.column.field == 'action'">
          <span v-if="deletingList.includes( row.row.action )" uk-spinner="ratio: 0.7" />
          <a v-else uk-icon="icon: trash; ratio: 1;" @click.prevent="deletePN( row.row.action )"></a>
        </span>
        <span v-else-if="row.column.field === 'scheduled_date'">
          {{$options.filters.formatDate( row.row.scheduled_date )}}
        </span>
      </template>
    </vue-good-table>

    <div v-if="(!allPushNotifications || !allPushNotifications.length) && !fetching">
      <span class="uk-text-meta">{{$t('Pages.PushNotifications.NoData')}}</span>
      <a class="uk-text-primary" uk-toggle="target: #push-notification-modal-multi-app">{{$t('Pages.PushNotifications.CreatingNotification')}}</a>
    </div>
    <div v-if="fetching && !(allPushNotifications && allPushNotifications.length)">
      <span uk-spinner></span>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import 'vue-good-table/dist/vue-good-table.css'
import { VueGoodTable } from 'vue-good-table';
import Notification from '@/components/util/Notification';
import PushNotificationModal from '@/components/modals/PushNotificationModal';

export default {
  name: 'PushNotificationsView',
  components: {
    VueGoodTable,
    PushNotificationModal
  },
  data() {
    return {
      deletingList: [],
      searchKey: this.$route?.query?.search || '',
      fetching: false,
      filterType: 'scheduled',
      filterClass: {
        scheduled: 'uk-active uk-text-bold',
        sent: '',
        all: ''
      },
    }
  },
  computed: {
    ...mapGetters([
      'allPushNotifications'
    ]),
    tableHeaders () {
      return [
        {
          label: this.$t('Pages.PushNotifications.Table.Title'), 
          field: 'title',
          thClass: '',
          tdClass: 'uk-text-truncate',
          width: '18%',
        }, {
          label: this.$t('Pages.PushNotifications.Table.Message'),
          field: 'message',
          thClass: '',
          tdClass: 'uk-text-truncate',
          width: '18%',
          sortable: false,
        }, 
        {
          label: this.$t('Pages.PushNotifications.Table.Apps'),
          field: 'apps',
          thClass: 'uk-width-medium',
          tdClass: 'uk-text-truncate',
          width: '18%',
          sortable: false,
        }, 
        {
          label: this.$t('Pages.PushNotifications.Table.IsScheduled'),
          field: 'isScheduled',
          thClass: 'uk-text-center uk-width-medium',
          tdClass: 'uk-text-center uk-width-medium',
          width: '15%',
          sortable: true,
        }, 
        {
          label: this.$t('Pages.PushNotifications.Table.ScheduledDate'),
          field: 'scheduled_date',
          thClass: 'uk-text-center uk-width-large',
          tdClass: 'uk-text-center uk-width-medium',
           width: '15%',
          sortable: true,
        }, 
        {
          label: this.$t('Pages.PushNotifications.Table.Type'),
          field: 'type',
          thClass: 'uk-text-center custom-center',
          tdClass: 'uk-text-center',
          width: '8%',
          sortable: true,
        }, {
          label: this.$t('Pages.PushNotifications.Table.Action'),
          field: 'action',
          thClass: 'uk-text-center',
          tdClass: 'uk-text-center',
          sortable: false,
          html: true,
          width: '6%'
        }
      ]
    },
    filteredNotifications () {
      if (!this.allPushNotifications?.length) return []
      const searchKey = this.searchKey.toLowerCase()
      const notifications = this.allPushNotifications.map( p => {
        return {
          title: p.title,
          message: ( p.body ) ? p.body : p.message,
          apps: p.application?.name,
          scheduled_date: ( p.createdAt ) ? new Date(p.createdAt) : new Date(p.scheduledFor),
          type: p.sent ? (( p.testing ) ? this.$t('Pages.PushNotifications.Testing') : this.$t('Pages.PushNotifications.Live')) : (p.scheduledMode ? (this.$t(`Pages.PushNotifications.Type.${p.scheduledMode}`)) : (( p.testing ) ? this.$t('Pages.PushNotifications.Testing') : this.$t('Pages.PushNotifications.Live'))),
          action: { id: p.id, title: p.title },
          sent: p.sent,
          createdAt: p.createdAt,
          sentPn: ((p.sent === true) || (p.createdAt)),
          isScheduled: (p.sent === false) ? this.$t('Generic.Labels.Yes') : this.$t('Generic.Labels.No')
        }
      } )
      return notifications.filter(pn => {
        const searchResult = (pn.title.toLowerCase().includes(searchKey) || pn.message?.toLowerCase().includes(searchKey) || pn.body?.toLowerCase().includes(searchKey) )
        if (this.filterType === 'all') {
          return searchResult
        } else if (this.filterType === 'sent') {
          return (((pn.sent === true) || (pn.createdAt)) && searchResult)
        } else  if (this.filterType === 'scheduled') {
          return ((pn.sent === false) && searchResult)
        }
      })
    }
  },
  beforeDestroy () {
    const modalElement = document.getElementById('push-notification-modal-multi-app')
    if (modalElement) {
      window.UIkit.modal('#push-notification-modal-multi-app').$destroy(true);
    }
  },
  methods: {
    changeFilter( type ) {
      this.filterType = type;
      this.filterClass = {
        all: '',
        sent: '',
        scheduled: ''
      }
      this.filterClass[ type ] = 'uk-active uk-text-bold';
    },
    async fetchPushNotifications() {
      this.fetching = true
      await this.$store.dispatch('fetchPushNotifications', { orgId: this.$route.params.id });    
      this.fetching = false
    },
    deletePN( notification ) {
      let confirmMessage = this.$t( 'DeleteModal.ConfirmDeletePushNotification', { name: notification.title } );
      window.UIkit.modal.confirm( confirmMessage, { labels: {
                cancel: this.$t('Actions.Cancel'), ok: this.$t('Actions.Delete')
            }} ).then(async () => {
        this.deletingList.push( notification );

        await this.$store.dispatch('deleteNotification', { notificationId: notification.id });
        await this.fetchPushNotifications();
        Notification.showNotification( this.$t( 'DeleteModal.Successful' ), this.$t( 'DeleteModal.PushNotificationDeleted', { name: notification.title } ) );
      },()=>{});
    },
    updateSearchKeyInURL () {
      const url = new URL(window.location);
      url.searchParams.set('search', this.searchKey);
      window.history.pushState({}, '', url);
    },
    clearSearch () {
      this.searchKey = '';
      const url = new URL(window.location);
      url.searchParams.delete('search');
      window.history.pushState({}, '', url);
    }
  },
  created() {
    this.fetchPushNotifications();
    this.$store.dispatch('fetchAllOrgApps', { orgId: this.$route.params.id })
  }
}
</script>

<style lang="scss">
table.vgt-table, .vgt-input {
  background: none;
  border: 1px solid var(--border-color);
}
.vgt-inner-wrap {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.vgt-global-search {
  background: none;
  border: 0px;
  padding-top: 10px;
  padding-bottom: 10px;
}
.vgt-global-search label {
  position: absolute;
  left: 10px;
}
.vgt-global-search__input .input__icon .magnifying-glass  {
  margin-left: 0px;
}
.vgt-global-search .vgt-input {
  width: 40%;
  margin-left: -40px;
  height: 40px;
  padding-left: 40px;
  color: var(--text-color);
}
.vgt-global-search .vgt-input::placeholder {
  color: var(--text-color);
}
table.vgt-table {
  font-size: 12px;
  border: 0px;
}
table.vgt-table td {
  border: 0px;
  color: var(--text-color);
}
.vgt-table thead th {
  background: none;
  color: var(--white-color);
  border: 0px;
  width: initial;
}
.vgt-table th.line-numbers {
  background: none;
  border-right: 1px solid var(--border-color);
}
.vgt-table th.sortable button {
  width: auto;
  position: relative;
  margin-left: 5px;
  top: -.25rem;
}
.vgt-table th.sortable button:after {
  border-bottom: 5px solid var(--white-color);
}
.vgt-table th.sortable button:before {
  border-top: 5px solid var(--white-color);
}
.vgt-table .custom-center span {
  padding-left: 10px;
}
.vgt-wrap__footer {
  background: none;
  border: 0px;
}
.vgt-wrap__footer .footer__navigation__page-info__current-entry {
  background: none;
  border: 1px solid var(--border-color);
  color: #909399;
  padding: 2px;
}
.vgt-wrap__footer .footer__row-count__label {
  font-size: 13px;
}
.floating-button {
  position: absolute;
  right: 30px;
  margin-top: 60px;
  z-index: 1;
}

.new-btn {
  padding: 0 25px;
}
</style>